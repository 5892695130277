
import { createApp } from 'vue'
import App from './App.vue'

import router from './router/router'

// import "https://kit.fontawesome.com/051eb6694c.js"

import functions from './helpers/Mixins'

import '@/css/styles.css'
import '@/css/mdb.min.css'
import '@/assets/mdb.min.js'


createApp(App).use(router).mount('#app')
